@import '../styles/globals.scss';

.main {
    width: 100%;
    height: 100%;
    background-color: #DC0000;
    display: flex;
    opacity: 1;
    justify-content: center;
    align-items: center;
    transition: opacity 2s ease;
    position: absolute;
    top:0px;
    left: 0px;
    z-index: 9988;

    &.hidden {
        opacity: 0;
        pointer-events:none;
    }
}

.logo {
    width: 40%;

    @include sm {
        width: 30%;
    }
}

.title {
    position: absolute;
    top: 0px;
    left: 0px;
    padding-left: 10px;
    padding-top: 12px;
    opacity: 0;
    transition: opacity 2s ease-in;

    p {
        font-family: $engFont;
        color: white;
        font-size: 25px;
        padding-bottom: 10px;
    }

    &.active {
        opacity: 1;
    }

    &.japanese {
        p {
            font-family: $jpFont;
        }
    }

    @include sm {
        padding-left: 10px;
        padding-top: 12px;
        p {
            font-size: 25px;
            padding-bottom: 20px;
        }
    }

    @include md {
        padding-left: 20px;
        padding-top: 19px;
        p {
            font-size: 28pt;
            padding-bottom: 20px;
        }
    }

    @include lg {
        padding-top: 17px;
        p {
            font-size: 30pt;
            padding-bottom: 20px;
        }
    }

    @include xl {
        p {
            font-size: 30pt;
            padding-bottom: 20px;
        }
    }
}

.sidebar {
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0;
    transition: opacity 2s ease-in 1s;
   

    &.active {
        opacity: 1;
        
        z-index: 999999;
    }

    @include sm {
        width: 50px;
    }

    @include md {
        width: 65px;
    }
}

.startButton {
    width: 100%;
    height: 50px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: $engFont;
    transform-origin: left;
    transform: rotate(90deg) translateY(-50%);
    font-size: 15pt;
    opacity: 1;
    transition: opacity 0.5s ease;
    position: absolute;
    top: 0px;

    &:hover {
        opacity: 0.4;
    }

    &.japanese {
        font-family: $jpFont;
        width: 100%;
        height: auto;
        transform: rotate(0deg) translateY(0%);
        padding-top: 30px;
        padding-left: 10%;
        padding-right: 10%;
        line-height: 29px;
        text-align: center;
    }

    @include sm {
        height: 50px;
        font-size: 15pt;
    }

    @include md {
        height: 65px;
        font-size: 24px;
    }
}

.button {
    width: 100%;
    height: auto;
    font-family: $engFont;
    font-size: 15pt;
    color: white;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 20px;
    opacity: 0.4;
    transition: opacity 0.5s ease;

    &:hover {
        cursor: pointer;
        opacity: 1;
    }

    &.active {
        opacity: 1;
    }

    &.japanese {
        font-family: $jpFont;
        line-height: 29px;
        padding-bottom: 40px;
    }

    @include sm {
        font-size: 15pt;
    }

    @include md {
        font-size: 24px;
    }
}