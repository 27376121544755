@import '../styles/globals.scss';

.main {
    width: calc(100% - 40px);
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: white;
    border-right-width: 1px;
    border-right-style: solid;
    z-index: 10;
    transform: translateX(0px);
    transition: transform 0.5s ease 0.2s;

    &.hidden {
        transition: transform 0.5s ease 0s;
        transform: translateX(-100%);
    }
    
    @include sm {
        border-right-width: 1px;
        width:330px;
        z-index: 1;
    }

    @include md {
        width:400px;
    }

    @include lg {
        width:500px;
    }

    @include xl {
        width:600px;
    }
}

.title {
    width: 100%;
    height: 50px;
    display: flex;
    padding-left: 10px;
    align-items: center;

    border-bottom-width: 1px;
    border-bottom-style: solid;
    font-family: $engFont;

    transition: border-color 0.5s ease;

    h2 {
        
        font-weight: 400;
        font-size: 25px;
        color: #4B4B4B;
        
    }

    @include sm {
        height: 50px;
        padding-left: 10px;

        h2 {
            font-size: 25px;
        }
    }

    @include md {
        height: 75px;
        padding-left: 20px;

        h2 {
            font-size: 28pt;
        }
    }

    @include lg {
        height: 75px;
        padding-left: 20;

        h2 {
            font-size: 30pt;
        }
    }

    @include xl {
        height: 75px;
        padding-left: 20;

        h2 {
            font-size: 30pt;
        }
    }
}


.talkList {
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: scroll;
    overflow-x: hidden;
    

    @include sm {
        height: calc(100% - 50px);

       
    }

    @include md {
        height: calc(100% - 75px);
        
    }

    @include lg {
        height: calc(100% - 75px);
        
    }

    @include xl {
        
    }
}

.talk {
    width: 100%;
    height: auto;
    display: flex;
    padding-bottom: 20px;
    align-items: baseline;
    color: #4B4B4B;
    text-decoration: none;
    transition: color 0.5s ease;

    @include sm {
        padding-bottom: 15px;
    }

    @include md {
        padding-bottom: 20px;
    }

    @include lg {
        padding-bottom: 30px;
    }

    @include xl {
        padding-bottom: 30px;
    }
    
}

.date {
    display: inline;
    font-family: $engFont;
    font-weight: 400;
    font-size: 13pt;
    padding-right: 20px;

    @include sm {
        font-size: 13pt;
    }

    @include md {
        font-size: 14pt;
    }

    @include lg {
        font-size: 20px;
    }

    @include xl {
        font-size: 19pt;
    }
}

.talkTitle {
    display: inline-flex;
    align-items: baseline;
    width: 450px;
    height: 100%;
    font-family: $engFont;
    font-weight: 400;
    font-size: 13pt;
    line-height: 18pt;
    border-bottom-width: 0px;

    @include sm {
        font-size: 13pt;
        line-height: 17pt;
    }

    @include md {
        font-size: 15pt;
        line-height: 18pt;
    }

    @include lg {
        font-size: 20px;
        line-height: 25px;
    }

    @include xl {
        font-size: 19pt;
        line-height: 24pt;
    }
   
}

.japanese {
    font-family: $jpFont;
}