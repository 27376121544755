@import "../styles/globals.scss";

.textBox {
  width: auto;
  max-width: 85%;
  height: auto;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  padding: 20px;
  opacity: 1;
  z-index: 99988;
  position: relative;
  pointer-events: auto;

  transform: translateX(15px) translateY(25px); 


  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  // &.up {
  //   transform: translateY(-100%);
  // }

  // &.right {
  //   transform: translateX(-110%);
  // }

  @include sm {
    position: absolute;
    padding: 10px;
    width: 260px;

    &.right {
      transform: translateX(-110%);
    }

    &.wide {
      width: 460px;
    }
  }

  @include md {
    padding: 20px;
    width: 300px;

    &.right {
      transform: translateX(-110%);
    }

    &.wide {
      width: 500px;
    }
  }

  @include lg {
    padding: 20px;
    width: 350px;

    &.right {
      transform: translateX(-110%);
    }

    &.wide {
      width: 550px;
    }
  }

  @include xl {
    padding: 20px;
    width: 400px;

    &.right {
      transform: translateX(-110%);
    }

    &.wide {
      width: 600px;
    }
  }

}

.imageBox {
  width: auto;
  height: auto;
  padding: 0px;
  opacity: 1;
  z-index: 99988;
  position: absolute;
  
  
 // transform: translateX(15px) translateY(25px);

  // &.right {
  //   transform: translateX(-400px);

  //   @include sm {
  //     transform: translateX(-210px);
  //   }
  
  //   @include md {
  //     transform: translateX(-410px);
  //   }
  
  //   @include lg {
  //     transform: translateX(-460px);
  //   }
  
  //   @include xl {
  //     transform: translateX(-510px);
  //   }

  //   &.portrait {
  //     @include sm {
  //       transform: translateX(-110px);
  //     }
    
  //     @include md {
  //       transform: translateX(-310px);
  //     }
    
  //     @include lg {
  //       transform: translateX(-360px);
  //     }
    
  //     @include xl {
  //       transform: translateX(-410px);
  //     }
  //   }
  // }

  // &.up {
  //   transform: translateY((-100%));
  // }
  

  &.hidden {
    opacity: 0;
  }
}

.image {
 // max-width: 400px;
 // max-height: 400px;
  position: relative;
  top:0px;
  left: 0px;
  border-width: 1px;
  border-style: solid;

  @include sm {
    width: 200px;
  }

  @include md {
    width: 400px;
  }

  @include lg {
    width: 450px;

  }

  @include xl {
    width: 500px;
  }

  &.portrait {
    height: 200px;
    width: auto;

    @include sm {
      height: 100px;
      width: auto;
    }
  
    @include md {
      height: 300px;
      width: auto;
    }
  
    @include lg {
      height: 350px;
      width: auto;
  
    }
  
    @include xl {
      height: 400px;
      width: auto;
    }
  }

}

.text {
  font-family: $engFont;
  font-size: 20px;
  line-height: 25px;
  color: #4b4b4b;
  font-style: italic;

  &.japanese {
    font-family: $jpFont;
    font-style: normal;
  }

  @include sm {
    font-size: 20px;
    line-height: 25px;
  }

  @include md {
    font-size: 20px;
    line-height: 25px;
  }

  @include lg {
    font-size: 20px;
    line-height: 25px;
  }

  @include xl {
    font-size: 20px;
    line-height: 25px;
  }
  
}

.close {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.cross {
  height: 1px;
  width: 20px;
  position: absolute;
  left: 3px;
  top: 45%;
  transform-origin: center;
}