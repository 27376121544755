@import "../styles/globals.scss";

.main {
  height: 100%;
  max-height: 100vh;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;

  @include sm {
    width: calc(100% - 330px - 50px);
    left: 330px;
  }

  @include md {
    width: calc(100% - 400px - 65px);
    left: 400px;
  }

  @include lg {
    width: calc(100% - 500px - 65px);
    left: 500px;
  }

  @include xl {
    width: calc(100% - 600px - 65px);
    left: 600px;
  }
}

.title {
  width: 100%;
  padding-left: 10px;
  padding-top: 35px;
  height: auto;

  p {
    font-size: 33px;
    line-height: 38px;
    font-family: $engFont;
    color: #4b4b4b;
  }

  &.japanese {
    p {
      font-family: $jpFont;
    }
  }

  @include sm {
    width: 92%;
    padding-left: 30px;
    padding-top: 10px;

    p {
      font-size: 40px;
      line-height: 45px;
    }
  }

  @include md {
    padding-left: 50px;
    padding-top: 15px;

    p {
      font-size: 45px;
      line-height: 50px;
    }
  }

  @include lg {
    padding-left: 60px;
    padding-top: 15px;

    p {
      font-size: 60px;
      line-height: 68px;
    }
  }

  @include xl {
    padding-left: 70px;
    padding-top: 15px;

    p {
      font-size: 70px;
      line-height: 78px;
    }
  }
}

.body {
  width: 100%;
  height: auto;
  padding-left: 40px;
  padding-top: 20px;
  padding-right: 30px;
  padding-bottom: 60px;

  p {
    font-family: $engFont;
    font-size: 13pt;
    line-height: 18pt;
    color: #4b4b4b;
    padding-bottom: 20px;

    em {
      font-style: italic;
    }

    strong {
      font-weight: 700;
    }
  }

  h3 {
    font-family: $engFont;
    font-weight: 700;
    color: #4b4b4b;
    font-size: 15pt;
    padding-top: 20pt;
    padding-bottom: 20px;
  }

  &.japanese {
    p {
      font-family: $jpFont;

      em {
        font-style: normal;
      }
      strong {
        font-weight: 700;
      }
    }

    h3 {
      font-family: $jpFont;
      font-weight: 700;
    }
  }

  @include sm {
    padding-left: 60px;
    padding-top: 20px;
    padding-right: 30px;

    p {
      font-size: 18px;
      line-height: 24px;
    }

    h3 {
      font-size: 23px;
      padding-top: 27px;
    }
  }

  @include md {
    padding-left: 100px;
    padding-top: 40px;
    padding-right: 55px;

    p {
      font-size: 14pt;
      line-height: 18pt;
    }

    h3 {
      font-size: 18pt;
      padding-top: 25pt;
    }
  }

  @include lg {
    padding-left: 120px;
    padding-top: 40px;
    padding-right: 55px;

    p {
      font-size: 20px;
      line-height: 25px;
    }

    h3 {
      font-size: 26px;
      padding-top: 20px;
    }
  }

  @include xl {
    padding-left: 140px;
    padding-top: 40px;
    padding-right: 55px;

    p {
      font-size: 24px;
      line-height: 29px;
    }

    h3 {
      font-size: 28px;
      padding-top: 20px;
    }
  }
}

.poem {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  pre {
    font-family: $engFont;
    font-size: 13pt;
    line-height: 18pt;
    color: #4b4b4b;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  @include sm {
    pre {
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  @include md {
    pre {
      font-size: 14pt;
      line-height: 18pt;
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }

  @include lg {
    pre {
      font-size: 20px;
      line-height: 25px;
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }

  @include xl {
    pre {
      font-size: 24px;
      line-height: 29px;
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }
}

.paragraph {
  width: 100%;
  height: auto;
  display: flex;

  .bullet {
    display: inline-block;
    width: 9%;
  }

  .indent {
    display: inline-block;
    width: 90%;

    pre {
      font-family: $engFont;
      font-size: 13pt;
      line-height: 18pt;
      color: #4b4b4b;
      padding-bottom: 20px;
      white-space: pre-wrap;

      @include sm {
        font-size: 18px;
        line-height: 24px;
      }

      @include md {
        font-size: 14pt;
        line-height: 18pt;
      }

      @include lg {
        font-size: 20px;
        line-height: 25px;
      }

      @include xl {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
}

.subtitle {
  position: relative;
  right: 30px;
  bottom: 10px;
  

  p {
    font-size: 20px;
    font-style: italic;
    line-height: 1.2;
    padding-bottom: 40px;
  }

  @include sm {
    right: 30px;

    p {
      font-size: 23px;
    }
  }

  @include md {
    right: 50px;

    p {
      font-size: 24px;
    }
  }

  @include lg {
    right: 60px;

    p {
      font-size: 26px;
    }
  }

  @include xl {
    right: 70px;

    p {
      font-size: 28px;
    }
  }
}

.nextPage {
  padding-left: 10px;
  padding-bottom: 5px;

  a {
    text-decoration: none;
    font-family: $engFont;
    font-size: 13pt;
    line-height: 18pt;
  }

  &.japanese {
    a {
      font-family: $jpFont;
    }
  }

  @include sm {
    a {
      font-size: 18px;
    }
  }

  @include md {
    a {
      font-size: 14pt;
    }
  }

  @include lg {
    a {
      font-size: 20px;
    }
  }

  @include xl {
    a {
      font-size: 24px;
    }
  }
}
