.main {
    width: 25px;
    height: 17px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
}

.line {
    position: absolute;
    width: 100%;
    height: 1px;
}