.scrollarea-content{
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    touch-action: none;

    &:focus {
        outline: 0;
    }
}

.scrollarea{
    position: relative;
    overflow: hidden;

    .scrollbar-container{


        &.vertical{
            width: 15px;
            height: 100%;
            right: 0;
            top: 0;
            border-left-width: 1px;
            border-left-style: solid;
            border-left-color: #DC0000;
            transition: border-left-color 0.5s ease;

            .scrollbar{
                width: 15px;
                height: 20px;
                background-color: #DC0000;
                transition: background-color 0.5s ease;
            }
        }

        position: absolute;
        background: none;
        opacity: 1;
        z-index: 99;

        -webkit-transition: all .4s;
           -moz-transition: all .4s;
            -ms-transition: all .4s;
             -o-transition: all .4s;
                transition: all .4s;

        
    }

    
}