@import '../styles/globals.scss';

.main {
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    border-left-width: 0px;
    border-left-style: solid;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 4;
    background-color: white;
    transform: translateX(0%);
    transition: transform 0.5s ease 0.2s, border-color 0.5s ease;

    &.hidden {
        transition: transform 0.5s ease 0s;
        transform: translateX(100%);
    }

    @include sm {
        border-left-width: 1px;
        width: 50px;
    }

    @include md {
        width: 65px;
    }

    @include lg {
        width: 65px;
    }

    @include xl {
        width: 65px;
    }
}

.button {
    width: 100%;
    height: auto;
    font-family: $engFont;
    font-size: 15pt;
    color: #4B4B4B;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 20px;
    
    text-decoration: none;

    p {
        transition: color 0.5s ease;
    }

    &:hover {
        cursor: pointer;
        opacity: 1;
    }

    &.active {
        opacity: 1;
    }

    &.japanese {
        font-family: $jpFont;
        line-height: 29px;
        padding-bottom: 40px;
    }

    @include sm {
        font-size: 15pt;
    }

    @include md {
        font-size: 24px;
    }

    @include lg {
        font-size: 24px;
    }

    @include xl {
        font-size: 24px;
    }
}

.topButtons {
    width: 300px;
    height: 40px;
    position: absolute;
    transform-origin: top left;
    top: 0px;
    left: 100%;
    transform: rotate(90deg);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    opacity: 0;
    transition: opacity 2s ease-in 1.5s;
    color: #4B4B4B;

    a {
        text-decoration: none;
    }
    

    pre {
        display: inline;
        font-family: $engFont;
        font-size: 15pt;
        color: #4B4B4B;
        padding-right: 30px;
        transition: color 0.5s ease;
    }

    &.japanese {
        width: 40px;
        height: auto;
        top: 0px;
        left: calc(100% - 45px);
        transform: rotate(0deg);
        flex-wrap: wrap;
        padding-right: 20px;
        padding-top: 10px;

        a {
            display: block;
            padding-bottom: 30px;
            
        }

        pre{
            font-family: $jpFont;
            transition: color 0.5s ease;
        }
    }

    &.visible {
        opacity: 1;
    }

    @include sm {
        height: 50px;

        pre{
            font-size: 15pt;
        }

        &.japanese {
            width: 50px;
            left: calc(100% - 55px);
            align-items: center;
            padding-top: 10px;
        }
    }

    @include md {
        height: 65px;
        
        pre{
            font-size: 24px;
        }

        &.japanese {
            width: 65px;
            left: calc(100% - 65px);
            align-items: center;
            padding-top: 20px;
        }
    }

    @include lg {
        height: 65px;
        
        pre{
            font-size: 24px;
        }

        &.japanese {
            width: 65px;
            left: calc(100% - 65px);
            align-items: center;
            padding-top: 20px;
        }
    }

    @include xl {
        height: 65px;
        
        pre{
            font-size: 24px;
        }

        &.japanese {
            width: 65px;
            left: calc(100% - 65px);
            align-items: center;
            padding-top: 20px;
        }
    }
}