.cursor {
    width: 35px;
    height: 35px;
    position: absolute;
    opacity: 1;
    transform: translate(0px, 0px);

  
    &.hidden {
        opacity: 0;
    }
  
    
  
  }