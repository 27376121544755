@import "../styles/globals.scss";

.main {
  position: fixed;
  width: calc(100% - 15px);
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: white;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.3s ease;
  pointer-events: all;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  @include sm {
    width: calc(100% - 330px - 50px - 15px);
    left: 330px;
  }

  @include md {
    width: calc(100% - 400px - 65px - 15px);
    left: 400px;
  }

  @include lg {
    width: calc(100% - 500px - 65px - 15px);
    left: 500px;
  }

  @include xl {
    width: calc(100% - 600px - 65px - 15px);
        left: 600px;
  }
}

.gallery {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 20%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 10px;
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
  }


  // @include sm {
  //   width: 100%;
  //   height: 40%;
  //   top: 25%;
  // }

  // @include md {
  //   height: 45%;
  //   top: 23%;
  // }

  // @include lg {
  //   height: 50%;
  //   top: 20%;
  // }

  // @include xl {
  //   height: 60%;
  //   top: 15%;
  // }
}

.image {
  border-width: 1px;
  border-style: solid;
  width: 100%;
  height: auto;

  &.portrait {
    height: 100%;
    width: auto;
  }
}

.close {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 5px;
  right: 13px;

  @include sm {
    width: 65px;
    height: 65px;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.cross {
  width: 35px;
  height: 1px;
  position: absolute;
  left: 0px;
  top: 50%;
  transform-origin: center;

  @include sm {
    width: 50px;
    height: 1px;
    position: absolute;
    left: 0px;
    top: 50%;
    transform-origin: center;
  }
}

.caption {
  width: 95%;
  height: auto;
  padding-left: 20px;
  padding-bottom: 15px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  p {
    font-family: $engFont;
    font-size: 15px;
    line-height: 17px;
    color: #4b4b4b;
    padding-bottom: 20px;
  }

  &.japanese {
    p {
      font-family: $jpFont;
    }
  }

  @include sm {
    padding-left: 40px;
    padding-bottom: 20px;
      p {
        font-size: 15px;
        line-height: 17px;
      }
  }

  @include md {
    p {
      font-size: 14pt;
      line-height: 18pt;
    }
  }

  @include lg {
    p {
      font-size: 20px;
      line-height: 25px;
    }
  }

  @include xl {
    p {
      font-size: 24px;
      line-height: 29px;
    }
  }
}

.button {
  width: 50px;
  height: 50px;
  position: absolute;
  opacity: 0;
  pointer-events: none;

  &.previous {
    top: calc(50% - 50px);
    left: 2%;

    .arrow {
      left: 0px;
      width: 25px;
      transform-origin: left;
    }

  }

  &.next {
    top: calc(50% - 50px);
    right: 2%;

    .arrow {
      right: 0px;
      width: 25px;
      transform-origin: right;
    }
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  @include sm {
    width: 65px;
    height: 65px;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  
    &.previous {
      top: 40%;
      left: 2%;
  
      .arrow {
        left: 0px;
        width: 40px;
        transform-origin: left;
      }
  
    }
  
    &.next {
      top: 40%;
      right: 2%;
  
      .arrow {
        right: 0px;
        width: 40px;
        transform-origin: right;
      }
    }
  }
}

.arrow {
  width: 40px;
  height: 1px;
  position: absolute;
  top: 50%;
}
