
@import './type.scss';
@import './colors.scss';
@import './breakpoints.scss';
@import './reset.scss';


*::-webkit-scrollbar {
    display: none;
  }

* {
    cursor: none;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}